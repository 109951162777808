import {
  Newcookies,
  Newdatasubject,
  Newpolicy,
} from "assets/svg/iconantdcustom";
import IntlMessage from "components/util-components/IntlMessage";

export const PDPAKIT_MODULE = [
  {
    name: "Cookie Consent Manager",
    id: "cookie_consent_manager",
    path: "/apps/pdpakit/cookies",
    component: Newcookies,
    subpath: "cookies",
    text: <IntlMessage id="papakit.module.cookie.text" />,
  },
  {
    name: "Policy Management",
    id: "policy_management",
    path: "/apps/pdpakit/policy",
    component: Newpolicy,
    subpath: "policy",
    text: <IntlMessage id="papakit.module.policy.text" />,
  },
  {
    name: "DSAR Automation",
    id: "dsar_automation",
    path: "/apps/pdpakit/datasubject",
    component: Newdatasubject,
    subpath: "datasubject",
    text: "Nullam dictum felis eu pede mollis pretium. Curabitur a felis in nunc fringilla tristique.",
  },
  {
    name: "Consent Management",
    id: "consent_management",
    path: "/apps/pdpakit/consent-management",
    component: Newdatasubject,
    subpath: "universal",
    text: "Nullam dictum felis eu pede mollis pretium. Curabitur a felis in nunc fringilla tristique.",
  },
];
