import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "OneForce";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const AUTH_BASE_URL =
  "https://0cidya7gwd.execute-api.ap-southeast-1.amazonaws.com/v1";
export const SES_BASE_URL = "https://ses.onefence.co/dev";
export const FEEDBACK_BASE_URL =
  "https://m8epkgyxhj.execute-api.ap-southeast-1.amazonaws.com/tenant";
export const DSAR_BASE_URL =
  "https://td265nvgm5.execute-api.us-east-2.amazonaws.com/v1";

export const ONE_FORCE_WEBFORM_BASE_URL =
  "https://3ulz9p9wf9.execute-api.ap-southeast-1.amazonaws.com/v1";

export const APP_PREFIX_PATH = "/apps";
export const AUTH_PREFIX_PATH = "/auth";
export const PROFILE_PREFIX_PATH = "/profile";
export const PREVIEW_PREFIX_PATH = "/preview";
export const VERIFY_PREFIX_PATH = "/verification";
export const PANNEL_PREFIX_PATH = "/pannels";
export const WEBFORM_PREFIX_PATH = "/form";

export const ENTRY_ROUTE = "/auth/login";
export const TOKEN_PAYLOAD_KEY = "authorization";
export const PUBLIC_REQUEST_KEY = "public-request";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: localStorage.getItem("locale")
    ? localStorage.getItem("locale")
    : "th",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  showContact: false,
  hideSidebar: false,
};
